/* line 7, ../assets/sass/style_custom.scss */
.test {
  color: green;
}
/* line 9, ../assets/sass/style_custom.scss */
.test .test_sub {
  color: red;
}
/* line 13, ../assets/sass/style_custom.scss */
.test.test_sub2 {
  float: right;
  color: violet;
  display: flex;
  flex: 0;
}

/* line 28, ../assets/sass/style_custom.scss */
.navbar-static-top {
  z-index: 5;
}

/* line 32, ../assets/sass/style_custom.scss */
.sign-in-link a {
  color: red !important;
}
/* line 34, ../assets/sass/style_custom.scss */
.sign-in-link a:hover {
  text-decoration: underline !important;
}

/* line 40, ../assets/sass/style_custom.scss */
li.hello-user span {
  float: left;
  padding: 15px 4px 15px 35px;
  min-height: 50px;
}
/* line 45, ../assets/sass/style_custom.scss */
li.hello-user a {
  display: inline-block;
  padding-left: 0;
  font-weight: bold;
}

/* line 55, ../assets/sass/style_custom.scss */
#page-wrapper-loggedout {
  margin: 50px 0;
}

/* line 59, ../assets/sass/style_custom.scss */
.sidebar-search {
  margin-top: 20px;
}
/* line 61, ../assets/sass/style_custom.scss */
.sidebar-search .custom-search-form {
  display: none;
}

/* line 69, ../assets/sass/style_custom.scss */
.region-content {
  clear: both;
}

/* line 75, ../assets/sass/style_custom.scss */
html.js fieldset.collapsed {
  height: initial;
}

/* line 85, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets {
  overflow: hidden;
}
/* line 88, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-1 .views-exposed-widget {
  width: 32%;
  padding-right: 0;
  margin-left: 2%;
}
/* line 92, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-1 .views-exposed-widget:first-child {
  margin-left: 0;
}
/* line 98, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper {
  width: 100%;
  padding-right: 0;
}
/* line 101, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper .form-type-textfield {
  float: left;
  width: 49%;
}
/* line 104, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-2 #edit-timestamp-wrapper .form-type-textfield.form-item-timestamp-max {
  float: right;
  margin-top: -25px;
}
/* line 112, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-3 .views-exposed-widget {
  width: 49%;
  padding-right: 0;
  float: right;
}
/* line 116, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .block-widgets.row-3 .views-exposed-widget:first-child {
  float: left;
  margin-left: 0;
}
/* line 124, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .form-submit {
  background-color: #337ab7;
  border-color: #2e6da4;
}
/* line 127, ../assets/sass/style_custom.scss */
.page-user-archive .views-exposed-form .form-submit:hover {
  background-color: #286090;
  border-color: #204d74;
}
/* line 135, ../assets/sass/style_custom.scss */
.page-user-archive .form-control.input-sm {
  width: auto;
}
/* line 140, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn a {
  color: white;
}
/* line 142, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn a:hover {
  color: white;
}
/* line 146, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn.failed {
  background-color: red;
}
/* line 149, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn .label {
  top: initial;
}
/* line 152, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn .label-success, .page-user-archive .dataTables_wrapper .btn .label-default {
  background: transparent;
}
/* line 155, ../assets/sass/style_custom.scss */
.page-user-archive .dataTables_wrapper .btn .label {
  padding: 0;
  font-size: inherit;
}
/* line 166, ../assets/sass/style_custom.scss */
.page-user-archive table.dataTable thead .views-field-url.sorting:after, .page-user-archive table.dataTable thead .views-field-url.sorting_asc:after, .page-user-archive table.dataTable thead .views-field-url.sorting_desc:after {
  content: '';
}
/* line 172, ../assets/sass/style_custom.scss */
.page-user-archive table.dataTable .views-field-url {
  text-align: center;
}
/* line 178, ../assets/sass/style_custom.scss */
.page-user-archive .is-new .views-field-timestamp {
  font-weight: bold;
}
/* line 183, ../assets/sass/style_custom.scss */
.page-user-archive .views-field-status .btn-xs {
  font-size: 14px;
}

/* line 192, ../assets/sass/style_custom.scss */
table.dataTable tbody th, table.dataTable tbody td {
  vertical-align: middle;
}
/* line 196, ../assets/sass/style_custom.scss */
table.dataTable thead .sorting, table.dataTable thead .sorting_asc, table.dataTable thead .sorting_desc {
  background: none !important;
}

/* line 210, ../assets/sass/style_custom.scss */
.containerLogin .login-panel.panel-primary {
  border: none;
  margin-top: 0;
}
/* line 214, ../assets/sass/style_custom.scss */
.containerLogin .login-panel.panel-primary .panel-body {
  padding: 0;
}
/* line 219, ../assets/sass/style_custom.scss */
.containerLogin #edit-actions {
  margin-bottom: 0;
}
/* line 223, ../assets/sass/style_custom.scss */
.containerLogin form {
  padding: 15px;
}
/* line 225, ../assets/sass/style_custom.scss */
.containerLogin form #edit-cas-login-redirection-message, .containerLogin form #edit-cas-login-redirection-message--2 {
  display: none;
  text-align: center;
  margin: 5px 0 0 0;
  color: #bababa;
}
/* line 233, ../assets/sass/style_custom.scss */
.containerLogin form .or {
  text-align: center;
  margin: 5px 0 0 0;
  color: gray;
}
/* line 240, ../assets/sass/style_custom.scss */
.containerLogin form .cas-links {
  padding: 0;
}
/* line 242, ../assets/sass/style_custom.scss */
.containerLogin form .cas-links .cas-link a {
  color: white;
}
/* line 244, ../assets/sass/style_custom.scss */
.containerLogin form .cas-links .cas-link a:hover {
  text-decoration: none;
}
/* line 248, ../assets/sass/style_custom.scss */
.containerLogin form .cas-links .uncas-link {
  width: 120px;
  margin: auto;
}
/* line 253, ../assets/sass/style_custom.scss */
.containerLogin form button[id*="edit-submit-google"], .containerLogin form .btn-google-plus {
  background-image: none !important;
  width: 100% !important;
  margin: 5px auto 10px !important;
  text-align: center !important;
  height: 33px !important;
  padding: 0 !important;
}
/* line 261, ../assets/sass/style_custom.scss */
.containerLogin form button[id*="edit-submit-google"] .stub, .containerLogin form .btn-google-plus .stub {
  display: none;
}
/* line 264, ../assets/sass/style_custom.scss */
.containerLogin form button[id*="edit-submit-google"] i, .containerLogin form .btn-google-plus i {
  line-height: 33px;
  font-size: 1.6em;
  border-right: 1px solid white;
  margin-right: 5px;
  padding-right: 5px;
}

/* line 278, ../assets/sass/style_custom.scss */
.modal-content .containerLogin {
  width: 100%;
  margin: 0;
}

/* line 286, ../assets/sass/style_custom.scss */
.not-logged-in.page-not-found-404 #page-wrapper-loggedout, .not-logged-in.access-denied-403 #page-wrapper-loggedout {
  margin: 100px 0;
  text-align: center;
}
/* line 290, ../assets/sass/style_custom.scss */
.not-logged-in.page-not-found-404 footer, .not-logged-in.access-denied-403 footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}
/* line 300, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .navbar-fixed-top {
  position: relative;
}
/* line 305, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .row {
  margin: 0;
}
/* line 310, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin {
  margin-top: 5%;
  padding: 0 30px;
}
/* line 315, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin #name-and-slogan {
  text-align: right;
  background: url(../img/logo_bg.png) no-repeat left top;
  display: inline-block;
  margin-bottom: 15px;
}
/* line 321, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin #name-and-slogan #site-name a {
  color: #0a50a1;
  font-family: "MyriadProSemibold","DejaVu Serif",Georgia,Constantia,serif;
  font-size: 34px;
  font-style: italic;
  padding: 15px 0;
}
/* line 327, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin #name-and-slogan #site-name a:hover {
  text-decoration: none;
}
/* line 332, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin .login-panel.panel-primary {
  border: 1px solid #337ab7;
}
/* line 334, ../assets/sass/style_custom.scss */
.not-logged-in.page-user .containerLogin .login-panel.panel-primary .panel-body {
  padding: 15px;
}
/* line 340, ../assets/sass/style_custom.scss */
.not-logged-in.page-user footer {
  text-align: center;
  margin-top: 50px;
}

/* line 356, ../assets/sass/style_custom.scss */
#user-pass .extra-links, #user-register-form .extra-links {
  margin-top: 10px;
}

/* line 363, ../assets/sass/style_custom.scss */
#user-register-form .tabledrag-toggle-weight-wrapper {
  display: none;
}
/* line 366, ../assets/sass/style_custom.scss */
#user-register-form .tabledrag-hide {
  display: none !important;
}
/* line 369, ../assets/sass/style_custom.scss */
#user-register-form .sticky-header {
  display: none;
}
/* line 373, ../assets/sass/style_custom.scss */
#user-register-form th {
  display: none;
}
/* line 377, ../assets/sass/style_custom.scss */
#user-register-form th.field-label {
  display: table-cell;
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
/* line 386, ../assets/sass/style_custom.scss */
#user-register-form .form-required {
  color: red;
}
/* line 389, ../assets/sass/style_custom.scss */
#user-register-form tr {
  background: none;
}
/* line 392, ../assets/sass/style_custom.scss */
#user-register-form td {
  border: none;
  padding: 0;
}
/* line 395, ../assets/sass/style_custom.scss */
#user-register-form td.field-multiple-drag {
  display: none;
}

/* line 407, ../assets/sass/style_custom.scss */
.page-user-addressbook #accordionOtherAddressBooks .panel-heading {
  background: #5cb85c;
  color: white;
}
/* line 410, ../assets/sass/style_custom.scss */
.page-user-addressbook #accordionOtherAddressBooks .panel-heading:hover {
  background: #449d44;
}
/* line 415, ../assets/sass/style_custom.scss */
.page-user-addressbook .form-item-operation {
  width: 65%;
  float: left;
  margin-right: 10px;
  max-width: 400px;
}
/* line 423, ../assets/sass/style_custom.scss */
.page-user-addressbook .form-control.input-sm {
  width: auto;
}
/* line 427, ../assets/sass/style_custom.scss */
.page-user-addressbook .views-field-views-bulk-operations, .page-user-addressbook .views-field-edit {
  text-align: center;
}
/* line 434, ../assets/sass/style_custom.scss */
.page-user-addressbook thead .views-field-views-bulk-operations.sorting_asc:after, .page-user-addressbook thead .views-field-views-bulk-operations.sorting_desc:after, .page-user-addressbook thead .views-field-views-bulk-operations.sorting:after, .page-user-addressbook thead .views-field-edit.sorting_asc:after, .page-user-addressbook thead .views-field-edit.sorting_desc:after, .page-user-addressbook thead .views-field-edit.sorting:after {
  content: '';
}

/* line 449, ../assets/sass/style_custom.scss */
#crc-message-edit-outbound-form .form-item-message-value {
  margin: 0;
}
/* line 452, ../assets/sass/style_custom.scss */
#crc-message-edit-outbound-form #edit-message-format-help {
  float: right;
}

/* line 456, ../assets/sass/style_custom.scss */
.page-send #modal-content {
  overflow-y: auto;
}

/* line 466, ../assets/sass/style_custom.scss */
.user-detail-page .field-name-field-twilio-number div div, .user-detail-page .field-name-field-fax-number div div, .page-user-unassign-fax .field-name-field-twilio-number div div, .page-user-unassign-fax .field-name-field-fax-number div div, .page-user-unassign-twilio .field-name-field-twilio-number div div, .page-user-unassign-twilio .field-name-field-fax-number div div {
  margin-bottom: 10px;
}
/* line 470, ../assets/sass/style_custom.scss */
.user-detail-page .field-name-field-sabredav-image, .page-user-unassign-fax .field-name-field-sabredav-image, .page-user-unassign-twilio .field-name-field-sabredav-image {
  margin: 30px 0;
}

/* line 475, ../assets/sass/style_custom.scss */
#crc-portal-select-twilio-form #edit-search, #crc-portal-select-twilio-form #edit-submit {
  float: left;
  margin-right: 10px;
}
/* line 479, ../assets/sass/style_custom.scss */
#crc-portal-select-twilio-form #edit-number {
  clear: both;
  padding-top: 10px;
}

/* line 485, ../assets/sass/style_custom.scss */
.crc-portal-unassign-did-form a#edit-cancel, .crc-portal-unassign-twilio-form a#edit-cancel {
  margin-right: 10px;
}

/* line 490, ../assets/sass/style_custom.scss */
.list-group-item.manage-numbers {
  overflow: hidden;
}
/* line 492, ../assets/sass/style_custom.scss */
.list-group-item.manage-numbers a, .list-group-item.manage-numbers .landline {
  float: right;
  text-align: right;
  color: gray;
}

/* line 498, ../assets/sass/style_custom.scss */
.number-labels span {
  margin-left: 10px;
}
/* line 501, ../assets/sass/style_custom.scss */
.number-labels span.label.enabled.fax {
  background: #f0ad4e;
}
/* line 504, ../assets/sass/style_custom.scss */
.number-labels span.label.enabled.sms {
  background: #337ab7;
}
/* line 507, ../assets/sass/style_custom.scss */
.number-labels span.label.enabled.voice {
  background: #5cb85c;
}

/* line 519, ../assets/sass/style_custom.scss */
.page-user-edit .form-type-password-confirm .form-type-password {
  margin-bottom: 0;
}
/* line 523, ../assets/sass/style_custom.scss */
.page-user-edit .form-type-password-confirm .control-label .label {
  float: right;
  margin-left: 5px;
}
/* line 527, ../assets/sass/style_custom.scss */
.page-user-edit .form-type-password-confirm .progress {
  height: 4px;
}
/* line 530, ../assets/sass/style_custom.scss */
.page-user-edit .form-type-password-confirm .form-control-feedback {
  right: 10px;
}
/* line 533, ../assets/sass/style_custom.scss */
.page-user-edit .form-type-password-confirm .help-block.password-help {
  margin: 0 0 0 15px;
  color: #aaaaaa;
  clear: both;
}
/* line 539, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications {
  border-bottom: 1px dotted gray;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
/* line 543, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .sticky-header {
  display: none;
}
/* line 546, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications label[for="edit-field-notifications-und-0-field-notification-und"] {
  font-size: 18px;
  font-weight: normal;
}
/* line 551, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .table-responsive > .table > tbody > tr > td {
  white-space: normal;
}
/* line 554, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .tabledrag-toggle-weight-wrapper {
  display: none;
}
/* line 558, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-multiple-table td {
  padding: 0;
  border: none;
  padding: 15px 0;
  border-bottom: 1px dotted gray;
}
/* line 564, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-multiple-table tr:last-child td {
  border: none !important;
  padding-bottom: 0 !important;
}
/* line 568, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-multiple-table thead, .page-user-edit .field-name-field-notifications .field-multiple-table .field-multiple-drag {
  display: none;
}
/* line 571, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-multiple-table.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: transparent;
}
/* line 574, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-multiple-table.table-hover > tbody > tr:hover {
  background-color: transparent;
}
/* line 578, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-add-more-submit {
  background-color: #337ab7;
  border-color: #2e6da4;
}
/* line 581, ../assets/sass/style_custom.scss */
.page-user-edit .field-name-field-notifications .field-add-more-submit :hover {
  background-color: #286090;
  border-color: #204d74;
}

/* line 590, ../assets/sass/style_custom.scss */
fieldset.collapsible {
  border: none;
}

/* line 593, ../assets/sass/style_custom.scss */
.panel-default > .panel-heading {
  margin: 0;
}

/* line 596, ../assets/sass/style_custom.scss */
.panel-collapse {
  border: 1px solid #ddd;
  border-top: none;
}

/* line 604, ../assets/sass/style_custom.scss */
body.page-send #edit-recipients-method {
  overflow: hidden;
  margin: 30px 0 10px 0;
}
/* line 608, ../assets/sass/style_custom.scss */
body.page-send #edit-recipients-method .form-type-radio {
  float: left;
  margin: 0 5px 10px;
}
/* line 613, ../assets/sass/style_custom.scss */
body.page-send .form-item-recipients.form-autocomplete {
  margin-bottom: 40px;
}
/* line 617, ../assets/sass/style_custom.scss */
body.page-send #edit-channels-list {
  overflow: hidden;
}
/* line 620, ../assets/sass/style_custom.scss */
body.page-send #edit-channels-list .form-type-checkbox {
  float: left;
  margin: 0 5px 25px;
}

/* Status buttons at Message archives */
/* line 631, ../assets/sass/style_custom.scss */
.views-field-status {
  text-align: center;
}
/* line 633, ../assets/sass/style_custom.scss */
.views-field-status .btn {
  background-color: gray;
}
/* line 635, ../assets/sass/style_custom.scss */
.views-field-status .btn.btn-warning {
  background-color: #f0ad4e;
}
/* line 638, ../assets/sass/style_custom.scss */
.views-field-status .btn.btn-success {
  background-color: #5cb85c;
}
/* line 641, ../assets/sass/style_custom.scss */
.views-field-status .btn .label {
  background-color: transparent;
  white-space: normal;
  padding: 0;
}

/* Add buttons on Company/Departments pages */
/* line 651, ../assets/sass/style_custom.scss */
.add-buttons {
  margin-bottom: 10px;
}
/* line 653, ../assets/sass/style_custom.scss */
.add-buttons ul.action-links {
  padding: 0;
  overflow: hidden;
}
/* line 656, ../assets/sass/style_custom.scss */
.add-buttons ul.action-links li {
  float: left;
  list-style: none;
  margin-right: 4px;
}

/* Make popover states window scrollable */
/* line 665, ../assets/sass/style_custom.scss */
.popover-content {
  max-height: 250px;
  overflow: auto;
}

/* Message item view page, add right margin for labels */
/* line 671, ../assets/sass/style_custom.scss */
.page-message- span.label-default {
  margin-right: 5px;
}

/*# sourceMappingURL=style_custom.css.map */
